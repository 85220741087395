import './crystals.css'

function Crystals() {
    return (
        <div>
            <div id="worldCrystals" style={{zIndex:-1}}>
                <div class="crystal_01"></div>
                <div class="crystal_02"></div>
                <div class="crystal_03"></div>    
            </div>
            <div id="crystals" style={{zIndex:-1}}>
                <div class="crystal_01"></div>
                <div class="crystal_02"></div>
                <div class="crystal_03"></div>    
            </div>
  
        </div>
    )
}

export default Crystals;