import Crystals from "./components/CRYSTALS/Crystals";
import Terminal from "./components/TERMINAL/Terminal";
import React from "react";

const App = () => {
	const [ theme, setTheme ] = React.useState('dark')
	const themeVars = theme === 'dark' ? {
		app: {backgroundColor: '#333444'},
		terminal: {boxShadow: '0 2px 5px #111'},
		window: {backgroundColor: '#2A87F6', color: '#000000'},
		field: {backgroundColor: '#222333', color: '#F4F4F4', fontWeight: 'normal'},
		cursor: {animation : '1.02s blink-dark step-end infinite'}
	} : {
		app: {backgroundColor: '#ACA9BB'},
		terminal: {boxShadow: '0 2px 5px #33333375'},
		window: {backgroundColor: '#2A87F6', color: '#E3E3E3'},
		field: {backgroundColor: '#E3E3E3', color: '#474554', fontWeight: 'bold'},
		cursor: {animation : '1.02s blink-light step-end infinite'}
	}
	
	return <div id="app">
    <Crystals  />
		<Terminal style={{ zIndex: +1,display:"absolute" }} theme={themeVars} setTheme={setTheme}/>
    
	</div>
}

export default App;
